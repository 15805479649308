<script setup lang="ts">
</script>

<template>
  <catalog-image></catalog-image>
  <section class="assortment">
    <common-page-header></common-page-header>
    <section class="assortment-container flex-col">
      <div class="assortment-header flex-col">
        <div class="flex-c-b">
          <h1>{{ $t('components.assortment.header.assortment') }}</h1>
        </div>
      </div>
      <slot></slot>
    </section>
    <catalog-footer></catalog-footer>
  </section>
</template>

<style lang="scss" scoped>
.assortment {
  @apply padding-top-for-fixed-header;

  &-container {
    @apply px-4 md:px-[5%] py-8 gap-8;
  }
}
</style>
